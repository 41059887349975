@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
#home {
  background-color: #000000; }

@media (max-width: 800px) {
  #home-banner {
    flex-direction: column;
    overflow: hidden; } }

@media (max-width: 650px) {
  #home-banner {
    min-height: 600px; } }

@media (max-width: 500px) {
  #home-banner {
    min-height: 566px; } }

@media (max-width: 400px) {
  #home-banner {
    min-height: 533px; } }

#home-container-farafix {
  width: 100%;
  padding-left: 5%; }

@media (max-width: 800px) {
  #home-container-farafix {
    padding-left: 0; } }

@media (max-width: 650px) {
  #home-container-farafix {
    margin-top: 20%; } }

@media (max-width: 550px) {
  #home-container-farafix {
    margin-top: 25%; } }

@media (max-width: 500px) {
  #home-container-farafix {
    margin-top: 30%; } }

#home-subtitle {
  font-family: 'Coolvetica Black'; }

@media (max-width: 500px) {
  #home-subtitle {
    font-size: 18px; } }

@media (max-width: 450px) {
  #home-subtitle {
    font-size: 17px; } }

@media (max-width: 400px) {
  #home-subtitle {
    font-size: 16px; } }

@media (max-width: 350px) {
  #home-subtitle {
    font-size: 14px; } }

@media (max-width: 650px) {
  #home-location h4 {
    font-size: 21px; } }

@media (max-width: 500px) {
  #home-location h4 {
    font-size: 20px; } }

@media (max-width: 450px) {
  #home-location h4 {
    font-size: 19px; } }

@media (max-width: 400px) {
  #home-location h4 {
    font-size: 18px; } }

@media (max-width: 350px) {
  #home-location h4 {
    font-size: 17px; } }

#home-container-celular {
  height: 1000px;
  width: 800px; }

#home-container-celular-mobile {
  transform: translateY(40%);
  min-height: 400px;
  height: 50%; }

@media (max-width: 550px) {
  #home-container-celular-mobile {
    transform: translateY(35%); } }

@media (max-width: 500px) {
  #home-container-celular-mobile {
    transform: translateY(30%); } }

#home-container-celular-mobile-img {
  width: auto;
  transform: rotate(-60deg); }

.home-celular-img {
  height: 400px; }

@media (max-width: 2000px) {
  .home-celular-img {
    height: calc(400px * 0.95); }
  #home-container-celular {
    width: calc(800px * 0.95); } }

@media (max-width: 1800px) {
  .home-celular-img {
    height: calc(400px * 0.86); }
  #home-container-celular {
    width: calc(800px * 0.86); } }

@media (max-width: 1600px) {
  .home-celular-img {
    height: calc(400px * 0.77); }
  #home-container-celular {
    width: calc(800px * 0.77); } }

@media (max-width: 1400px) {
  .home-celular-img {
    height: calc(400px * 0.68); }
  #home-container-celular {
    width: calc(800px * 0.68); } }

@media (max-width: 1200px) {
  .home-celular-img {
    height: calc(400px * 0.55); }
  #home-container-celular {
    width: calc(800px * 0.55); } }

@media (max-width: 1000px) {
  .home-celular-img {
    height: calc(400px * 0.50); }
  #home-container-celular {
    width: calc(800px * 0.50); } }

@media (max-width: 900px) {
  .home-celular-img {
    height: calc(400px * 0.48); }
  #home-container-celular {
    width: calc(800px * 0.45); } }

#home-celular-1 {
  z-index: 4;
  transform: translateX(4%) translateY(66%) rotate(-57deg);
  animation: cel1 3s forwards;
  animation-delay: 0.5s; }

#home-celular-2 {
  z-index: 3;
  transform: translateX(-95%) translateY(65%) rotate(-55deg);
  animation: cel2 3s forwards;
  animation-delay: 0.5s; }

#home-celular-3 {
  z-index: 2;
  transform: translateX(-194%) translateY(81%) rotate(-55deg);
  animation: cel3 3s forwards;
  animation-delay: 0.5s; }

#home-celular-4 {
  z-index: 1;
  transform: translateX(-292%) translateY(77%) rotate(-55deg);
  animation: cel4 3s forwards;
  animation-delay: 0.5s; }

@keyframes cel1 {
  0% {
    transform: translateX(4%) translateY(66%) rotate(-57deg); }
  100% {
    transform: translateX(-29%) translateY(5%) rotate(-55deg); } }

@keyframes cel2 {
  0% {
    transform: translateX(-95%) translateY(65%) rotate(-55deg); }
  100% {
    transform: translateX(-119%) translateY(29%) rotate(-55deg); } }

@keyframes cel3 {
  0% {
    transform: translateX(-194%) translateY(81%) rotate(-55deg); }
  100% {
    transform: translateX(-211%) translateY(52%) rotate(-55deg); } }

@keyframes cel4 {
  0% {
    transform: translateX(-292%) translateY(77%) rotate(-55deg); }
  100% {
    transform: translateX(-302%) translateY(57%) rotate(-55deg); } }

#home-section-1 {
  background: url("../img/placa-2.webp") center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 1000px; }

@media (max-width: 2000px) {
  #home-section-1 {
    height: 950px; } }

@media (max-width: 1800px) {
  #home-section-1 {
    height: 850px; } }

@media (max-width: 1600px) {
  #home-section-1 {
    height: 750px; } }

@media (max-width: 1400px) {
  #home-section-1 {
    height: 700px; } }

@media (max-width: 1200px) {
  #home-section-1 {
    height: 650px;
    background-attachment: scroll; } }

@media (max-width: 1000px) {
  #home-section-1 {
    height: 580px; } }

@media (max-width: 800px) {
  #home-section-1 {
    height: 500px; } }

@media (max-width: 550px) {
  #home-section-1 {
    height: 450px; } }

@media (max-width: 450px) {
  #home-section-1 {
    height: 500px; } }

#home-section-1-title {
  margin-bottom: 45px; }

@media (max-width: 2000px) {
  #home-section-1-title {
    margin-bottom: calc(40px * 1); } }

@media (max-width: 1800px) {
  #home-section-1-title {
    margin-bottom: calc(40px * 0.95); } }

@media (max-width: 1600px) {
  #home-section-1-title {
    margin-bottom: calc(40px * 0.87); } }

@media (max-width: 1400px) {
  #home-section-1-title {
    margin-bottom: calc(40px * 0.81); } }

@media (max-width: 1200px) {
  #home-section-1-title {
    margin-bottom: calc(40px * 0.75); } }

@media (max-width: 1000px) {
  #home-section-1-title {
    margin-bottom: calc(40px * 0.70); } }

@media (max-width: 800px) {
  #home-section-1-title {
    margin-bottom: calc(40px * 0.65); } }

@media (max-width: 600px) {
  #home-section-1-title {
    margin-bottom: calc(40px * 0.65); } }

@media (max-width: 500px) {
  #home-section-1-title {
    margin-bottom: calc(40px * 0.65); } }

@media (max-width: 400px) {
  #home-section-1-title {
    margin-bottom: calc(40px * 0.65); } }

#home-section-1 h3 {
  padding-bottom: 5px; }

@media (max-width: 650px) {
  #home-section-1 h4 {
    max-width: 400px;
    text-align: center; } }

@media (max-width: 450px) {
  #home-section-1 h4 {
    max-width: 350px; } }

@media (max-width: 350px) {
  #home-section-1 h4 {
    max-width: 250px; } }

#home-section-2 {
  height: 1100px;
  font-family: 'Coolvetica Black'; }

@media (max-width: 2000px) {
  #home-section-2 {
    height: 1000px; } }

@media (max-width: 1800px) {
  #home-section-2 {
    height: 950px; } }

@media (max-width: 1600px) {
  #home-section-2 {
    height: 850px; } }

@media (max-width: 1400px) {
  #home-section-2 {
    height: 800px; } }

@media (max-width: 1200px) {
  #home-section-2 {
    height: 680px; } }

@media (max-width: 1000px) {
  #home-section-2 {
    height: 730px; } }

@media (max-width: 800px) {
  #home-section-2 {
    height: 700px; } }

@media (max-width: 600px) {
  #home-section-2 {
    height: 950px; } }

@media (max-width: 500px) {
  #home-section-2 {
    height: 920px; } }

@media (max-width: 400px) {
  #home-section-2 {
    height: 850px; } }

#home-section-2 h2 {
  margin-bottom: 55px;
  transform: translateY(20px);
  opacity: 0; }

@media (max-width: 2000px) {
  #home-section-2 h2 {
    margin-bottom: calc(55px * 0.95); } }

@media (max-width: 1800px) {
  #home-section-2 h2 {
    margin-bottom: calc(55px * 0.86); } }

@media (max-width: 1600px) {
  #home-section-2 h2 {
    margin-bottom: calc(50px * 0.77); } }

@media (max-width: 1400px) {
  #home-section-2 h2 {
    margin-bottom: calc(45px * 0.68); } }

@media (max-width: 1200px) {
  #home-section-2 h2 {
    margin-bottom: calc(40px * 0.65); } }

@media (max-width: 1000px) {
  #home-section-2 h2 {
    margin-bottom: calc(40px * 0.60); } }

@media (max-width: 600px) {
  #home-section-2 h2 {
    margin-bottom: calc(40px * 0.57);
    margin-top: 0; } }

@media (max-width: 400px) {
  #home-section-2 h2 {
    margin-top: 20px; } }

@media (max-width: 400px) {
  #home-section-2 h5 {
    font-size: 14px; } }

@media (max-width: 350px) {
  #home-section-2 h5 {
    font-size: 13px; } }

#home-section-2-grid {
  flex-wrap: wrap;
  width: 2000px; }

@media (max-width: 2000px) {
  #home-section-2-grid {
    width: 1800px; } }

@media (max-width: 1800px) {
  #home-section-2-grid {
    width: 1600px; } }

@media (max-width: 1600px) {
  #home-section-2-grid {
    width: 1500px; } }

@media (max-width: 1500px) {
  #home-section-2-grid {
    width: 1400px; } }

@media (max-width: 1400px) {
  #home-section-2-grid {
    width: 1300px; } }

@media (max-width: 1300px) {
  #home-section-2-grid {
    width: 1200px; } }

@media (max-width: 1200px) {
  #home-section-2-grid {
    width: 1100px; } }

@media (max-width: 1100px) {
  #home-section-2-grid {
    width: 1010px; } }

@media (max-width: 1000px) {
  #home-section-2-grid {
    width: 800px;
    max-height: 410px;
    overflow: hidden; } }

@media (max-width: 800px) {
  #home-section-2-grid {
    width: 700px; } }

@media (max-width: 600px) {
  #home-section-2-grid {
    width: 500px;
    max-height: 1000px; } }

@media (max-width: 330px) {
  #home-section-2-grid {
    width: 400px; } }

.home-section-2-grid-item {
  margin: 30px;
  width: 310px;
  transform: translateY(20px);
  opacity: 0; }

@media (max-width: 2000px) {
  .home-section-2-grid-item {
    margin: calc(30px * 0.95);
    width: 294px; } }

@media (max-width: 1800px) {
  .home-section-2-grid-item {
    margin: calc(30px * 0.86);
    width: 266px; } }

@media (max-width: 1600px) {
  .home-section-2-grid-item {
    margin: calc(30px * 0.77);
    width: 238px; } }

@media (max-width: 1500px) {
  .home-section-2-grid-item {
    margin: calc(30px * 0.73);
    width: 223px; } }

@media (max-width: 1400px) {
  .home-section-2-grid-item {
    margin: calc(30px * 0.70);
    width: 210px; } }

@media (max-width: 1300px) {
  .home-section-2-grid-item {
    margin: calc(30px * 0.66);
    width: 192px; } }

@media (max-width: 1200px) {
  .home-section-2-grid-item {
    margin: calc(30px * 0.62);
    width: 180px; } }

@media (max-width: 1100px) {
  .home-section-2-grid-item {
    margin: calc(30px * 0.58);
    width: 167px; } }

@media (max-width: 400px) {
  .home-section-2-grid-item {
    margin: calc(30px * 0.58) calc(30px * 0.20); } }

@media (max-width: 330px) {
  .home-section-2-grid-item {
    margin: calc(30px * 0.58) 0;
    width: 150px; } }

.home-section-2-icon-container {
  height: 120px;
  width: 120px; }

@media (max-width: 2000px) {
  .home-section-2-icon-container {
    height: calc(120px * 0.95);
    width: calc(120px * 0.95); } }

@media (max-width: 1800px) {
  .home-section-2-icon-container {
    height: calc(120px * 0.86);
    width: calc(120px * 0.86); } }

@media (max-width: 1500px) {
  .home-section-2-icon-container {
    height: calc(120px * 0.77);
    width: calc(120px * 0.77); } }

@media (max-width: 1400px) {
  .home-section-2-icon-container {
    height: calc(120px * 0.68);
    width: calc(120px * 0.68); } }

@media (max-width: 1200px) {
  .home-section-2-icon-container {
    height: calc(120px * 0.65);
    width: calc(120px * 0.65); } }

@media (max-width: 1000px) {
  .home-section-2-icon-container {
    height: calc(120px * 0.60);
    width: calc(120px * 0.60); } }

@media (max-width: 800px) {
  .home-section-2-icon-container {
    height: calc(120px * 0.55);
    width: calc(120px * 0.55); } }

.home-section-2-grid-item .bi {
  font-size: 70px; }

@media (max-width: 2000px) {
  .home-section-2-grid-item .bi {
    font-size: calc(70px * 0.95); } }

@media (max-width: 1800px) {
  .home-section-2-grid-item .bi {
    font-size: calc(70px * 0.86); } }

@media (max-width: 1600px) {
  .home-section-2-grid-item .bi {
    font-size: calc(70px * 0.77); } }

@media (max-width: 1400px) {
  .home-section-2-grid-item .bi {
    font-size: calc(70px * 0.68); } }

@media (max-width: 1200px) {
  .home-section-2-grid-item .bi {
    font-size: calc(70px * 0.65); } }

@media (max-width: 1000px) {
  .home-section-2-grid-item .bi {
    font-size: calc(70px * 0.60); } }

@media (max-width: 800px) {
  .home-section-2-grid-item .bi {
    font-size: calc(70px * 0.55); } }

#home-section-3 {
  background: url("../img/placa-1.webp") center no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 1000px; }

@media (max-width: 2000px) {
  #home-section-3 {
    height: 850px; } }

@media (max-width: 1800px) {
  #home-section-3 {
    height: 800px; } }

@media (max-width: 1600px) {
  #home-section-3 {
    height: 750px; } }

@media (max-width: 1400px) {
  #home-section-3 {
    height: 700px; } }

@media (max-width: 1200px) {
  #home-section-3 {
    height: 650px;
    background-attachment: scroll; } }

@media (max-width: 1000px) {
  #home-section-3 {
    height: 500px; } }

@media (max-width: 800px) {
  #home-section-3 {
    height: 400px; } }

@media (max-width: 750px) {
  #home-section-3 {
    flex-direction: column;
    height: 450px; } }

@media (max-width: 500px) {
  #home-section-3 {
    height: 400px; } }

@media (max-width: 400px) {
  #home-section-3 {
    height: 360px; } }

.home-section-3-container {
  width: 650px; }

@media (max-width: 2000px) {
  .home-section-3-container {
    width: 622px; } }

@media (max-width: 1800px) {
  .home-section-3-container {
    width: 570px; } }

@media (max-width: 1600px) {
  .home-section-3-container {
    width: 510px; } }

@media (max-width: 1500px) {
  .home-section-3-container {
    width: 480px; } }

@media (max-width: 1400px) {
  .home-section-3-container {
    width: 460px; } }

@media (max-width: 1300px) {
  .home-section-3-container {
    width: 420px; } }

@media (max-width: 1200px) {
  .home-section-3-container {
    width: 375px; } }

@media (max-width: 1100px) {
  .home-section-3-container {
    width: 350px; } }

@media (max-width: 1000px) {
  .home-section-3-container {
    width: 343px; } }

@media (max-width: 768px) {
  .home-section-3-container {
    width: 305px; } }

@media (max-width: 750px) {
  .home-section-3-container {
    border: none;
    padding: 0 !important;
    width: 320px; } }

@media (min-width: 751px) {
  #home-section-3-container-1 {
    border-right: solid white 1px; } }

@media (max-width: 750px) {
  #home-section-3-container-1 {
    justify-content: center !important;
    height: auto !important; } }

@media (min-width: 751px) {
  #home-section-3-container-2 {
    border-left: solid white 1px; } }

@media (max-width: 750px) {
  #home-section-3-container-2 {
    height: auto !important;
    margin-top: 24px; } }

@media (max-width: 500px) {
  #home-section-3-container-2 {
    margin-top: 20px; } }

.hover-white-1 {
  transition: 0.3s ease; }

.hover-white-1:hover {
  color: #fafafa;
  transition: 0.3s ease; }

#tienda-productos {
  min-height: 70vh;
  padding-bottom: 36px; }

@media (max-width: 2000px) {
  #tienda-productos {
    padding-bottom: 34px; } }

@media (max-width: 1800px) {
  #tienda-productos {
    padding-bottom: 32px; } }

@media (max-width: 1600px) {
  #tienda-productos {
    padding-bottom: 30px; } }

@media (max-width: 1400px) {
  #tienda-productos {
    padding-bottom: 28px; } }

@media (max-width: 1200px) {
  #tienda-productos {
    padding-bottom: 26px; } }

@media (max-width: 1000px) {
  #tienda-productos {
    padding-bottom: 24px; } }

@media (max-width: 800px) {
  #tienda-productos {
    padding-bottom: 22px; } }

#tienda-banner {
  background-image: url("../img/celular-1.webp");
  background-size: cover;
  height: 600px; }

@media (max-width: 2000px) {
  #tienda-banner {
    height: 525px; } }

@media (max-width: 1800px) {
  #tienda-banner {
    height: 500px; } }

@media (max-width: 1600px) {
  #tienda-banner {
    height: 475px; } }

@media (max-width: 1400px) {
  #tienda-banner {
    height: 450px; } }

@media (max-width: 1200px) {
  #tienda-banner {
    height: 425px; } }

@media (max-width: 1000px) {
  #tienda-banner {
    height: 400px; } }

@media (max-width: 800px) {
  #tienda-banner {
    height: 375px; } }

@media (max-width: 600px) {
  #tienda-banner {
    height: 350px; } }

@media (max-width: 500px) {
  #tienda-banner {
    height: 325px; } }

@media (max-width: 400px) {
  #tienda-banner {
    height: 300px; } }

#tienda-indicador-pagina {
  padding: 36px;
  font-family: 'Coolvetica Black'; }

@media (max-width: 2000px) {
  #tienda-indicador-pagina {
    padding: 34px; } }

@media (max-width: 1800px) {
  #tienda-indicador-pagina {
    padding: 32px; } }

@media (max-width: 1600px) {
  #tienda-indicador-pagina {
    padding: 30px; } }

@media (max-width: 1400px) {
  #tienda-indicador-pagina {
    padding: 28px; } }

@media (max-width: 1200px) {
  #tienda-indicador-pagina {
    padding: 26px; } }

@media (max-width: 1000px) {
  #tienda-indicador-pagina {
    padding: 24px; } }

@media (max-width: 800px) {
  #tienda-indicador-pagina {
    padding: 22px; } }

#tienda-product {
  box-shadow: 0 8px 16px rgba(253, 253, 253, 0);
  width: 1000px;
  height: 385px;
  padding: 36px;
  flex-direction: row;
  align-items: start;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 36px;
  transition: 0.5s; }

@media (max-width: 2000px) {
  #tienda-product {
    width: 950px;
    height: 364px;
    padding: 34px;
    margin-bottom: 34px; } }

@media (max-width: 1800px) {
  #tienda-product {
    width: 900px;
    height: 343px;
    padding: 32px;
    margin-bottom: 32px; } }

@media (max-width: 1600px) {
  #tienda-product {
    width: 850px;
    height: 320px;
    padding: 30px;
    margin-bottom: 30px; } }

@media (max-width: 1400px) {
  #tienda-product {
    width: 800px;
    height: 306px;
    padding: 28px;
    margin-bottom: 28px; } }

@media (max-width: 1200px) {
  #tienda-product {
    width: 750px;
    height: 292px;
    padding: 26px;
    margin-bottom: 26px; } }

@media (max-width: 1000px) {
  #tienda-product {
    width: 700px;
    height: 273px;
    padding: 24px;
    margin-bottom: 24px; } }

@media (max-width: 800px) {
  #tienda-product {
    width: 570px;
    height: 197px;
    padding: 22px;
    margin-bottom: 22px; } }

@media (max-width: 600px) {
  #tienda-product {
    width: 480px;
    padding: 20px;
    margin-bottom: 20px; } }

@media (max-width: 500px) {
  #tienda-product {
    width: 90%;
    height: auto;
    padding: 30px;
    flex-direction: column;
    align-items: center; } }

@media (max-width: 450px) {
  #tienda-product {
    padding: 28px; } }

@media (max-width: 400px) {
  #tienda-product {
    padding: 26px; } }

@media (max-width: 350px) {
  #tienda-product {
    padding: 24px; } }

#tienda-product:hover {
  transform: scale(1.015);
  transition: 0.5s; }

#product-img-container {
  width: 40%;
  padding-right: 36px; }

@media (max-width: 2000px) {
  #product-img-container {
    width: 40%;
    padding-right: 34px; } }

@media (max-width: 1800px) {
  #product-img-container {
    width: 40%;
    padding-right: 32px; } }

@media (max-width: 1600px) {
  #product-img-container {
    width: 40%;
    padding-right: 30px; } }

@media (max-width: 1400px) {
  #product-img-container {
    width: 40%;
    padding-right: 28px; } }

@media (max-width: 1200px) {
  #product-img-container {
    width: 41%;
    padding-right: 26px; } }

@media (max-width: 1000px) {
  #product-img-container {
    width: 41%;
    padding-right: 24px; } }

@media (max-width: 800px) {
  #product-img-container {
    width: 35%;
    padding-right: 22px; } }

@media (max-width: 600px) {
  #product-img-container {
    width: 40%;
    padding-right: 20px; } }

@media (max-width: 500px) {
  #product-img-container {
    width: 100%;
    padding-right: 0; } }

#tienda-viendo {
  width: 1000px;
  padding: 32px 0;
  font-family: 'Coolvetica Black'; }

@media (max-width: 2000px) {
  #tienda-viendo {
    width: 950px;
    padding: 30px 0; } }

@media (max-width: 1800px) {
  #tienda-viendo {
    width: 900px;
    padding: 28px 0; } }

@media (max-width: 1600px) {
  #tienda-viendo {
    width: 850px;
    padding: 26px 0; } }

@media (max-width: 1400px) {
  #tienda-viendo {
    width: 800px;
    padding: 24px 0; } }

@media (max-width: 1200px) {
  #tienda-viendo {
    width: 750px; } }

@media (max-width: 1000px) {
  #tienda-viendo {
    width: 700px; } }

@media (max-width: 800px) {
  #tienda-viendo {
    width: 580px; } }

@media (max-width: 600px) {
  #tienda-viendo {
    width: 90%; } }

#tienda-product h5 {
  padding: 10px 0 21px 0;
  font-family: 'Coolvetica Black'; }

@media (max-width: 2000px) {
  #tienda-product h5 {
    padding: 9px 0 20px 0; } }

@media (max-width: 1800px) {
  #tienda-product h5 {
    padding: 8px 0 19px 0; } }

@media (max-width: 1600px) {
  #tienda-product h5 {
    padding: 7px 0 18px 0; } }

@media (max-width: 1400px) {
  #tienda-product h5 {
    padding: 6px 0 17px 0; } }

@media (max-width: 1200px) {
  #tienda-product h5 {
    padding: 5px 0 16px 0; } }

#product-desc-container {
  width: 66%; }

@media (max-width: 2000px) {
  #product-desc-container {
    width: 66%; } }

@media (max-width: 1800px) {
  #product-desc-container {
    width: 68%; } }

@media (max-width: 1600px) {
  #product-desc-container {
    width: 69%; } }

@media (max-width: 1400px) {
  #product-desc-container {
    width: 69%; } }

@media (max-width: 1200px) {
  #product-desc-container {
    width: 68%; } }

@media (max-width: 1000px) {
  #product-desc-container {
    width: 68%; } }

@media (max-width: 800px) {
  #product-desc-container {
    width: 69%; } }

@media (max-width: 600px) {
  #product-desc-container {
    width: 70%; } }

@media (max-width: 500px) {
  #product-desc-container {
    width: 100%; } }

#tienda-product h4 {
  font-family: 'Coolvetica Regular'; }

@media (max-width: 500px) {
  #tienda-product h4 {
    padding: 30px 0; } }

@media (max-width: 450px) {
  #tienda-product h4 {
    padding: 28px 0; } }

@media (max-width: 400px) {
  #tienda-product h4 {
    padding: 26px 0; } }

@media (max-width: 350px) {
  #tienda-product h4 {
    padding: 24px 0; } }

#tienda-product-hover-h5 {
  display: block;
  text-wrap: nowrap;
  position: relative;
  width: 0%;
  color: transparent;
  transition: 0.5s;
  transform: translateX(285px) translateY(300px); }

@media (max-width: 2000px) {
  #tienda-product-hover-h5 {
    transform: translateX(275px) translateY(284px); } }

@media (max-width: 1800px) {
  #tienda-product-hover-h5 {
    transform: translateX(267px) translateY(270px); } }

@media (max-width: 1600px) {
  #tienda-product-hover-h5 {
    transform: translateX(257px) translateY(253px); } }

@media (max-width: 1400px) {
  #tienda-product-hover-h5 {
    transform: translateX(258px) translateY(247px); } }

@media (max-width: 1200px) {
  #tienda-product-hover-h5 {
    transform: translateX(250px) translateY(239px); } }

@media (max-width: 1000px) {
  #tienda-product-hover-h5 {
    transform: translateX(236px) translateY(222px); } }

@media (max-width: 800px) {
  #tienda-product-hover-h5 {
    transform: translateX(170px) translateY(149px); } }

@media (max-width: 600px) {
  #tienda-product-hover-h5 {
    display: none; } }

#tienda-product:hover #tienda-product-hover-h5 {
  color: #3b3b3b79;
  transition: 0.5s; }

nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: 0.3s ease; }

nav h6 {
  letter-spacing: 0.05em;
  font-weight: 100;
  color: #eeeeee;
  font-family: 'Coolvetica Black';
  transition: 0.3s ease; }

nav h6:hover {
  color: #ffffff55;
  transition: 0.3s ease; }

footer {
  font-family: 'Coolvetica Black'; }

#all-r-r {
  font-size: 18px; }

@media (max-width: 2000px) {
  #all-r-r {
    font-size: 17px; } }

@media (max-width: 1800px) {
  #all-r-r {
    font-size: 16px; } }

@media (max-width: 1600px) {
  #all-r-r {
    font-size: 15px; } }

@media (max-width: 1400px) {
  #all-r-r {
    font-size: 14px; } }

@media (max-width: 1200px) {
  #all-r-r {
    font-size: 13px; } }

@media (max-width: 1000px) {
  #all-r-r {
    font-size: 12px; } }

@media (max-width: 800px) {
  #all-r-r {
    font-size: 11px; } }

footer h3 {
  transform: translateY(-13px); }

@media (max-width: 2000px) {
  footer h3 {
    transform: translateY(-12px); } }

@media (max-width: 1800px) {
  footer h3 {
    transform: translateY(-11px); } }

@media (max-width: 1600px) {
  footer h3 {
    transform: translateY(-10px); } }

@media (max-width: 1400px) {
  footer h3 {
    transform: translateY(-8px); } }

@media (max-width: 1200px) {
  footer h3 {
    transform: translateY(-6px); } }

@media (max-width: 1000px) {
  footer h3 {
    transform: translateY(-4px); } }

@media (max-width: 800px) {
  footer h3 {
    transform: translateY(-3px); } }

#footer-buttons h6 {
  transition: 0.2s ease; }

#footer-buttons h6:hover {
  color: #ffffff55;
  transition: 0.2s ease; }

@media (max-width: 580px) {
  #footer-vte {
    max-width: 120px;
    text-align: center; } }

#review-card {
  height: 340px;
  width: 300px;
  border-radius: 8%;
  font-family: 'Calibri', 'Inter';
  opacity: 0;
  scale: 0.2; }

@media (max-width: 2000px) {
  #review-card {
    height: calc(340px * 0.95);
    width: calc(300px * 0.95); } }

@media (max-width: 1800px) {
  #review-card {
    height: calc(340px * 0.90);
    width: calc(300px * 0.90); } }

@media (max-width: 1600px) {
  #review-card {
    height: calc(340px * 0.85);
    width: calc(300px * 0.85); } }

@media (max-width: 1400px) {
  #review-card {
    height: calc(340px * 0.80);
    width: calc(300px * 0.80); } }

@media (max-width: 1200px) {
  #review-card {
    height: calc(340px * 0.75);
    width: calc(300px * 0.75); } }

@media (max-width: 1000px) {
  #review-card {
    height: calc(340px * 0.70);
    width: calc(300px * 0.70); } }

@media (max-width: 800px) {
  #review-card {
    height: calc(340px * 0.65);
    width: calc(300px * 0.65); } }

@media (max-width: 600px) {
  #review-card {
    height: calc(340px * 0.65);
    width: calc(300px * 0.65); } }

@media (max-width: 500px) {
  #review-card {
    height: calc(340px * 0.65);
    width: calc(300px * 0.65); } }

@media (max-width: 400px) {
  #review-card {
    height: calc(340px * 0.65);
    width: calc(300px * 0.65); } }

#review-card-photo-name {
  font-size: 22px;
  font-weight: 600; }

@media (max-width: 2000px) {
  #review-card-photo-name {
    font-size: calc(22px * 0.95); } }

@media (max-width: 1800px) {
  #review-card-photo-name {
    font-size: calc(22px * 0.90); } }

@media (max-width: 1600px) {
  #review-card-photo-name {
    font-size: calc(22px * 0.85); } }

@media (max-width: 1400px) {
  #review-card-photo-name {
    font-size: calc(22px * 0.80); } }

@media (max-width: 1200px) {
  #review-card-photo-name {
    font-size: calc(22px * 0.75); } }

@media (max-width: 1000px) {
  #review-card-photo-name {
    font-size: calc(22px * 0.70); }
    #review-card-photo-name #review-hide {
      display: none; } }

@media (max-width: 800px) {
  #review-card-photo-name {
    font-size: calc(22px * 0.65); } }

@media (max-width: 600px) {
  #review-card-photo-name {
    font-size: calc(22px * 0.65); } }

@media (max-width: 500px) {
  #review-card-photo-name {
    font-size: calc(22px * 0.65); } }

@media (max-width: 400px) {
  #review-card-photo-name {
    font-size: calc(22px * 0.65); } }

#review-card-photo {
  width: 20%;
  border-radius: 100%;
  margin: 5%; }

#review-card-stars {
  color: #e7af22;
  border-top: solid 1px lightgray;
  padding: 3% 10% 0 10%;
  margin-bottom: 5%; }

#review-card-comment {
  font-size: 20px;
  margin-top: 1%;
  margin-left: 6%;
  margin-right: 6%;
  font-style: italic; }

@media (max-width: 2000px) {
  #review-card-comment {
    font-size: calc(20px * 0.95); } }

@media (max-width: 1800px) {
  #review-card-comment {
    font-size: calc(20px * 0.90); } }

@media (max-width: 1600px) {
  #review-card-comment {
    font-size: calc(20px * 0.85); } }

@media (max-width: 1400px) {
  #review-card-comment {
    font-size: calc(20px * 0.80); } }

@media (max-width: 1200px) {
  #review-card-comment {
    font-size: calc(20px * 0.75); } }

@media (max-width: 1000px) {
  #review-card-comment {
    font-size: calc(20px * 0.70); } }

@media (max-width: 800px) {
  #review-card-comment {
    font-size: calc(20px * 0.65); } }

@media (max-width: 600px) {
  #review-card-comment {
    font-size: calc(20px * 0.65); } }

@media (max-width: 500px) {
  #review-card-comment {
    font-size: calc(20px * 0.65); } }

@media (max-width: 400px) {
  #review-card-comment {
    font-size: calc(20px * 0.65); } }

#review-card {
  margin: 10px; }

@media (max-width: 2000px) {
  #review-card {
    margin: calc(10px * 0.95); } }

@media (max-width: 1800px) {
  #review-card {
    margin: calc(10px * 0.90); } }

@media (max-width: 1600px) {
  #review-card {
    margin: calc(10px * 0.85); } }

@media (max-width: 1400px) {
  #review-card {
    margin: calc(10px * 0.80); } }

@media (max-width: 1200px) {
  #review-card {
    margin: calc(10px * 0.75); } }

@media (max-width: 1000px) {
  #review-card {
    margin: calc(10px * 0.70); } }

@media (max-width: 800px) {
  #review-card {
    margin: calc(10px * 0.65); } }

@media (max-width: 600px) {
  #review-card {
    margin: calc(10px * 0.65); } }

@media (max-width: 500px) {
  #review-card {
    margin: calc(10px * 0.65); } }

@media (max-width: 400px) {
  #review-card {
    margin: calc(10px * 0.65); } }

@font-face {
  font-family: 'Coolvetica Regular';
  src: url("../fonts/coolvetica-rg.otf") format("truetype");
  font-style: normal; }

@font-face {
  font-family: 'Coolvetica Regular Italic';
  src: url("../fonts/coolvetica-rg-it.otf") format("truetype");
  font-style: italic; }

@font-face {
  font-family: 'Coolvetica Black';
  src: url("../fonts/CoolveticaBk-Regular.ttf") format("truetype"); }

.transparent {
  color: transparent; }

.b-transparent {
  background-color: transparent; }

.white-0 {
  color: #ffffff; }

.b-white-0 {
  background-color: #ffffff; }

.white-1 {
  color: #fafafa; }

.b-white-1 {
  background-color: #fafafa; }

.white-2 {
  color: #eeeeee; }

.b-white-2 {
  background-color: #eeeeee; }

.glass-0 {
  color: #ffffff55; }

.b-glass-0 {
  background-color: #ffffff55; }

.glass-1 {
  color: #3636360e; }

.b-glass-1 {
  background-color: #3636360e; }

.black-0 {
  color: #000000; }

.b-black-0 {
  background-color: #000000; }

.black-1 {
  color: #111111; }

.b-black-1 {
  background-color: #111111; }

.black-2 {
  color: #1e1e1e; }

.b-black-2 {
  background-color: #1e1e1e; }

.blue-0 {
  color: #bfc6ff; }

.b-blue-0 {
  background-color: #bfc6ff; }

.blue-1 {
  color: #0fc7ff; }

.b-blue-1 {
  background-color: #0fc7ff; }

.blue-2 {
  color: #0c95c0; }

.b-blue-2 {
  background-color: #0c95c0; }

.blue-3 {
  color: #1c2c31; }

.b-blue-3 {
  background-color: #1c2c31; }

.pink-0 {
  color: #fdd2ff; }

.b-pink-0 {
  background-color: #fdd2ff; }

.pink-1 {
  color: #ee18f9; }

.b-pink-1 {
  background-color: #ee18f9; }

.pink-2 {
  color: #b512bf; }

.b-pink-2 {
  background-color: #b512bf; }

.pink-3 {
  color: #301d31; }

.b-pink-3 {
  background-color: #301d31; }

.gray-0 {
  color: #00000055; }

.b-gray-0 {
  background-color: #00000055; }

.b-gradient-0B {
  background: linear-gradient(to bottom, #bfc6ff, #fdd2ff); }

.b-gradient-0T {
  background: linear-gradient(to top, #bfc6ff, #fdd2ff); }

.b-gradient-0R {
  background: linear-gradient(to right, #bfc6ff, #fdd2ff); }

.b-gradient-0L {
  background: linear-gradient(to left, #bfc6ff, #fdd2ff); }

.b-gradient-0bB {
  background: linear-gradient(to bottom, #bfc6ffe4, #fdd2ffe4); }

.b-gradient-0bT {
  background: linear-gradient(to top, #bfc6ffe4, #fdd2ffe4); }

.b-gradient-0bR {
  background: linear-gradient(to right, #bfc6ffe4, #fdd2ffe4); }

.b-gradient-0bL {
  background: linear-gradient(to left, #bfc6ffe4, #fdd2ffe4); }

.b-gradient-1B {
  background: linear-gradient(to bottom, #0fc7ff, #ee18f9); }

.b-gradient-1T {
  background: linear-gradient(to top, #0fc7ff, #ee18f9); }

.b-gradient-1R {
  background: linear-gradient(to right, #0fc7ff, #ee18f9); }

.b-gradient-1L {
  background: linear-gradient(to left, #0fc7ff, #ee18f9); }

.b-gradient-2B {
  background: linear-gradient(to bottom, #0c95c0, #b512bf); }

.b-gradient-2T {
  background: linear-gradient(to top, #0c95c0, #b512bf); }

.b-gradient-2R {
  background: linear-gradient(to right, #0c95c0, #b512bf); }

.b-gradient-2L {
  background: linear-gradient(to left, #0c95c0, #b512bf); }

.b-gradient-3B {
  background: linear-gradient(to bottom, #1c2c31, #301d31); }

.b-gradient-3T {
  background: linear-gradient(to top, #1c2c31, #301d31); }

.b-gradient-3R {
  background: linear-gradient(to right, #1c2c31, #301d31); }

.b-gradient-3L {
  background: linear-gradient(to left, #1c2c31, #301d31); }

.gradient-0B {
  background: linear-gradient(to bottom, #bfc6ff, #fdd2ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-0T {
  background: linear-gradient(to top, #bfc6ff, #fdd2ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-0R {
  background: linear-gradient(to right, #bfc6ff, #fdd2ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-0L {
  background: linear-gradient(to left, #bfc6ff, #fdd2ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-0bB {
  background: linear-gradient(to bottom, #bfc6ffe4, #fdd2ffe4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-0bT {
  background: linear-gradient(to top, #bfc6ffe4, #fdd2ffe4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-0bR {
  background: linear-gradient(to right, #bfc6ffe4, #fdd2ffe4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-0bL {
  background: linear-gradient(to left, #bfc6ffe4, #fdd2ffe4);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-1B {
  background: linear-gradient(to bottom, #0fc7ff, #ee18f9);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-1T {
  background: linear-gradient(to top, #0fc7ff, #ee18f9);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-1R {
  background: linear-gradient(to right, #0fc7ff, #ee18f9);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-1L {
  background: linear-gradient(to left, #0fc7ff, #ee18f9);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-2B {
  background: linear-gradient(to bottom, #0c95c0, #b512bf);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-2T {
  background: linear-gradient(to top, #0c95c0, #b512bf);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-2R {
  background: linear-gradient(to right, #0c95c0, #b512bf);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-2L {
  background: linear-gradient(to left, #0c95c0, #b512bf);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-3B {
  background: linear-gradient(to bottom, #1c2c31, #301d31);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-3T {
  background: linear-gradient(to top, #1c2c31, #301d31);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-3R {
  background: linear-gradient(to right, #1c2c31, #301d31);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.gradient-3L {
  background: linear-gradient(to left, #1c2c31, #301d31);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent; }

.w-95 {
  width: 95%; }

.w-90 {
  width: 90%; }

.w-85 {
  width: 85%; }

.w-80 {
  width: 80%; }

.w-75 {
  width: 75%; }

.w-70 {
  width: 70%; }

.w-65 {
  width: 65%; }

.w-60 {
  width: 60%; }

.w-55 {
  width: 55%; }

.w-45 {
  width: 45%; }

.w-40 {
  width: 40%; }

.w-35 {
  width: 35%; }

.w-33 {
  width: 33%; }

.w-30 {
  width: 30%; }

.w-25 {
  width: 25%; }

.w-20 {
  width: 20%; }

.w-15 {
  width: 15%; }

.w-10 {
  width: 10%; }

.w-5 {
  width: 5%; }

.h-95 {
  height: 95%; }

.h-90 {
  height: 90%; }

.h-85 {
  height: 85%; }

.h-80 {
  height: 80%; }

.h-75 {
  height: 75%; }

.h-70 {
  height: 70%; }

.h-65 {
  height: 65%; }

.h-60 {
  height: 60%; }

.h-55 {
  height: 55%; }

.h-45 {
  height: 45%; }

.h-40 {
  height: 40%; }

.h-35 {
  height: 35%; }

.h-33 {
  height: 33%; }

.h-30 {
  height: 30%; }

.h-25 {
  height: 25%; }

.h-20 {
  height: 20%; }

.h-15 {
  height: 15%; }

.h-10 {
  height: 10%; }

.h-5 {
  height: 5%; }

.border-none {
  border: none; }

.text-center {
  text-align: center; }

.button-transition {
  transition: 0.3s ease; }

.button-transition:hover {
  transition: 0.3s ease; }

.no-wrap {
  white-space: nowrap; }

.cursor-default {
  cursor: default !important; }

body {
  background-color: #1f1f1f; }

.body {
  font-family: 'Coolvetica Black', sans-serif; }

h1 {
  font-size: 220px; }

@media (max-width: 2000px) {
  h1 {
    font-size: 200px; } }

@media (max-width: 1800px) {
  h1 {
    font-size: 180px; } }

@media (max-width: 1600px) {
  h1 {
    font-size: 160px; } }

@media (max-width: 1400px) {
  h1 {
    font-size: 150px; } }

@media (max-width: 1200px) {
  h1 {
    font-size: 130px; } }

@media (max-width: 1000px) {
  h1 {
    font-size: 115px; } }

@media (max-width: 500px) {
  h1 {
    font-size: 100px; } }

@media (max-width: 450px) {
  h1 {
    font-size: 95px; } }

@media (max-width: 400px) {
  h1 {
    font-size: 80px; } }

@media (max-width: 350px) {
  h1 {
    font-size: 70px; } }

h2 {
  font-size: 120px; }

@media (max-width: 2000px) {
  h2 {
    font-size: 105px; } }

@media (max-width: 1800px) {
  h2 {
    font-size: 95px; } }

@media (max-width: 1600px) {
  h2 {
    font-size: 87px; } }

@media (max-width: 1400px) {
  h2 {
    font-size: 80px; } }

@media (max-width: 1200px) {
  h2 {
    font-size: 75px; } }

@media (max-width: 1000px) {
  h2 {
    font-size: 65px; } }

@media (max-width: 800px) {
  h2 {
    font-size: 55px; } }

h3 {
  font-size: 70px; }

@media (max-width: 2000px) {
  h3 {
    font-size: 60px; } }

@media (max-width: 1800px) {
  h3 {
    font-size: 56px; } }

@media (max-width: 1600px) {
  h3 {
    font-size: 52px; } }

@media (max-width: 1400px) {
  h3 {
    font-size: 46px; } }

@media (max-width: 1200px) {
  h3 {
    font-size: 38px; } }

@media (max-width: 1000px) {
  h3 {
    font-size: 32px; } }

@media (max-width: 800px) {
  h3 {
    font-size: 28px; } }

@media (max-width: 600px) {
  h3 {
    font-size: 28px; } }

@media (max-width: 500px) {
  h3 {
    font-size: 27px; } }

@media (max-width: 400px) {
  h3 {
    font-size: 26px; } }

h4 {
  font-size: 34px; }

@media (max-width: 2000px) {
  h4 {
    font-size: 32px; } }

@media (max-width: 1800px) {
  h4 {
    font-size: 30px; } }

@media (max-width: 1600px) {
  h4 {
    font-size: 28px; } }

@media (max-width: 1400px) {
  h4 {
    font-size: 25px; } }

@media (max-width: 1200px) {
  h4 {
    font-size: 22px; } }

@media (max-width: 1000px) {
  h4 {
    font-size: 20px; } }

h5 {
  font-size: 30px; }

@media (max-width: 2000px) {
  h5 {
    font-size: calc(30px * 0.95); } }

@media (max-width: 1800px) {
  h5 {
    font-size: calc(30px * 0.86); } }

@media (max-width: 1600px) {
  h5 {
    font-size: calc(30px * 0.77); } }

@media (max-width: 1500px) {
  h5 {
    font-size: calc(30px * 0.72); } }

@media (max-width: 1400px) {
  h5 {
    font-size: calc(30px * 0.68); } }

@media (max-width: 1300px) {
  h5 {
    font-size: calc(30px * 0.62); } }

@media (max-width: 1200px) {
  h5 {
    font-size: calc(30px * 0.58); } }

@media (max-width: 1100px) {
  h5 {
    font-size: calc(30px * 0.54); } }

@media (max-width: 1000px) {
  h5 {
    font-size: calc(30px * 0.53); } }

h6 {
  font-size: 27px; }

@media (max-width: 2000px) {
  h6 {
    font-size: 24px; } }

@media (max-width: 1800px) {
  h6 {
    font-size: 23px; } }

@media (max-width: 1600px) {
  h6 {
    font-size: 22px; } }

@media (max-width: 1400px) {
  h6 {
    font-size: 21px; } }

@media (max-width: 1200px) {
  h6 {
    font-size: 20px; } }

@media (max-width: 1000px) {
  h6 {
    font-size: 19px; } }

@media (max-width: 800px) {
  h6 {
    font-size: 18px; } }

@media (max-width: 600px) {
  h6 {
    font-size: 17px; } }

@media (max-width: 500px) {
  h6 {
    font-size: 16px; } }

@media (max-width: 425px) {
  h6 {
    font-size: 15px; } }

@media (max-width: 350px) {
  h6 {
    font-size: 14px; } }

i {
  font-size: 30px; }

@media (max-width: 2000px) {
  i {
    font-size: 28px; } }

@media (max-width: 1800px) {
  i {
    font-size: 25px; } }

@media (max-width: 1600px) {
  i {
    font-size: 22px; } }

@media (max-width: 1400px) {
  i {
    font-size: 20px; } }

@media (max-width: 1200px) {
  i {
    font-size: 18px; } }

@media (max-width: 1000px) {
  i {
    font-size: 16px; } }

@media (max-width: 800px) {
  i {
    font-size: 14px; } }

.hide-1000 {
  display: flex; }

@media (max-width: 1000px) {
  .hide-1000 {
    display: none !important; } }

.hide-800 {
  display: flex; }

@media (max-width: 800px) {
  .hide-800 {
    display: none !important; } }

.show-600 {
  display: none !important; }

@media (max-width: 600px) {
  .show-600 {
    display: flex !important; } }

.hide-650 {
  display: flex; }

@media (max-width: 650px) {
  .hide-650 {
    display: none !important; } }

.show-650 {
  display: none !important; }

@media (max-width: 650px) {
  .show-650 {
    display: flex !important; } }

@media (max-width: 450px) {
  .show-650 {
    flex-direction: column;
    align-items: center !important; } }

.hide-350 {
  display: contents; }

@media (max-width: 350px) {
  .hide-350 {
    display: none !important; } }

.show-350 {
  display: none !important; }

@media (max-width: 350px) {
  .show-350 {
    display: contents !important; } }

@keyframes fadeInUp {
  0% {
    filter: blur(1px);
    opacity: 0;
    transform: translateY(20px); }
  50% {
    filter: blur(0); }
  100% {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0); } }

.fade-in-up {
  animation: fadeInUp ease 0.7s forwards;
  animation-delay: 0.3s; }

@keyframes fadeInScale {
  0% {
    filter: blur(1px);
    opacity: 0;
    scale: 0.2; }
  50% {
    filter: blur(0); }
  100% {
    opacity: 1;
    scale: 1;
    filter: blur(0); } }

.fade-in-scale {
  animation: fadeInScale ease 0.7s forwards;
  animation-delay: 0.3s; }
